import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from 'src/context/useAuthContext';
import { PATH_AUTH } from 'src/routes/paths';

// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, isLoading } = useAuthContext();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (isLoading && !isInitialized) {
    return <LoadingScreen />;
  }

  if (isInitialized && !isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return (
      <Navigate
        to={PATH_AUTH.login}
        replace
      />
    );
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return (
      <Navigate
        to={requestedLocation}
        replace
      />
    );
  }

  return <> {children} </>;
}
