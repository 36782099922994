// i18n
import { HelmetProvider } from 'react-helmet-async';
// ----------------------------------------------------------------------
import { BrowserRouter } from 'react-router-dom';

import './locales/i18n';

import { MotionLazyContainer } from './components/animate';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider } from './components/settings';
import SnackbarProvider from './components/snackbar';
// Check our docs
// https://docs.minimals.cc/authentication/ts-version
import { AuthProvider } from './context/useAuthContext';
// locales
import ThemeLocalization from './locales';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <HelmetProvider>
      <SettingsProvider>
        <BrowserRouter>
          <ScrollToTop />
          <MotionLazyContainer>
            <ThemeProvider>
              <AuthProvider>
                <ThemeLocalization>
                  <SnackbarProvider>
                    <StyledChart />
                    <Router />
                  </SnackbarProvider>
                </ThemeLocalization>
              </AuthProvider>
            </ThemeProvider>
          </MotionLazyContainer>
        </BrowserRouter>
      </SettingsProvider>
    </HelmetProvider>
  );
}
