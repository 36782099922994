import { fetcher } from 'src/utils/legacy/api/fetcher';

import { API_URL } from '../../../../constants/api';

type SumItemsType = 'MWh' | 'tCO2' | 'count';

type UbaYearType = '2023' | '2024' | 'total';
type StatusType = 'requested' | 'submitted' | 'approved' | 'declined' | 'payout' | 'total';

type StatsType = Record<UbaYearType, Record<StatusType, Record<SumItemsType, number>>>;

export type QuotaAnalyticsType = {
  total: StatsType;
  monthly: {
    month: string;
    data: StatsType;
  }[];
};

/**
 * It makes a GET request to the `/quota-sales/analytics` endpoint and returns the data
 * @returns A promise that resolves to an object with the following properties:
 */
export const getQuotaAnalytics = async (): Promise<{ res: QuotaAnalyticsType; timestamp: string }> => {
  // const url = `${API_URL}/quota-sales/analytics`
  const url = `${API_URL}/analytics/quota-sales`;
  return { res: await fetcher(url), timestamp: new Date().toLocaleTimeString() };
};
