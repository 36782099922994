import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { getQuotaAnalytics, QuotaAnalyticsType } from 'src/pages/components/analytics-admin/api/getQuotaAnalytics';
import { getSummaryAnalytics } from 'src/pages/components/analytics-admin/api/getSummartAnalytics';
import { getUserAnalytics } from 'src/pages/components/analytics-admin/api/getUserAnalytics';
import { getVehicleAnalytics } from 'src/pages/components/analytics-admin/api/getVehicleAnalytics';

import { useAuthContext } from '../useAuthContext';
import { IUserListStatistics, UserAnalyticsType } from '../useUserAnalytics/userAnalyticsHelper';
import { VehicleAnalyticsUpdatedType } from '../useVehicleAnalytics/vehicleAnalyticsHelpers';

/**
 * Defines the apps global state
 * signature.
 */
interface IAnalyticsContext {
  vehicleAnalytics: VehicleAnalyticsUpdatedType;
  quotaAnalytics: QuotaAnalyticsType;
  userAnalytics: UserAnalyticsType;
  usersList?: IUserListStatistics;
  isLoading: boolean;
  error: unknown;
  refreshList: number;
  setRefreshList: Function;
}

const AnalyticsContext = createContext<IAnalyticsContext | null>(null);
export const useAnalyticsContext = (): IAnalyticsContext => useContext(AnalyticsContext)!;

export const AnalyticsProvider = (props: { children: any }): any => {
  const { isAuthenticated } = useAuthContext();
  const [vehicleAnalytics, setVehicleAnalytics] = useState<VehicleAnalyticsUpdatedType | null>(null);
  const [quotaAnalytics, setQuotaAnalytics] = useState<QuotaAnalyticsType | null>(null);
  const [userAnalytics, setUserAnalytics] = useState<UserAnalyticsType | null>(null);
  const [usersList, setUsersList] = useState<IUserListStatistics | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(undefined);
  // Indicates if the currently loaded list should be refreshed.
  const [refreshList, setRefreshList] = useState<number>(0);

  const removeLeadingZeroMonthsFromVehicles = (data: VehicleAnalyticsUpdatedType): VehicleAnalyticsUpdatedType => {
    let hasPositiveValues = false;
    const validData = data.monthly?.filter((month) => {
      if (hasPositiveValues) {
        return true;
      }

      hasPositiveValues = month.data.total.total.total > 0;
      return hasPositiveValues;
    });

    return {
      monthly: validData,
      total: data.total,
    };
  };

  const removeLeadingZeroMonthsFromQuotas = (data: any): any => {
    const validData: any[] = [];
    data.monthly.forEach((month: any) => {
      // Check if there is a property with a value higher than 0.
      const hasSignificantProperty = Object.keys(month.data.total).find((key: string) => !!month.data.total[key].count);
      if (!!validData.length || !!hasSignificantProperty) {
        validData.push(month);
      }
    });
    return {
      monthly: validData,
      total: data.total,
    };
  };

  const removeLeadingZeroMonthsFromUsers = (data: any): any => {
    const validData: any[] = [];
    data.monthly.forEach((month: any) => {
      // Check if there is a property with a value higher than 0.
      if (!!validData.length || !!month.data.total) {
        validData.push(month);
      }
    });
    return {
      monthly: validData,
      total: data.total,
    };
  };

  /**
   * It checks if the analytics object is empty, if it is, it calls the getVehicleAnalytics function
   * and sets the analytics object to the result of that function
   * @returns The analytics object is being returned.
   */
  const getAnalytics = useCallback(async () => {
    if (!vehicleAnalytics || isAuthenticated) {
      const [vehicles] = await Promise.all([getVehicleAnalytics()]);
      const cleanData = removeLeadingZeroMonthsFromVehicles({ ...vehicles });
      // const cleanData = removeLeadingZeroMonthsFromVehicles({...staticVehiclesData});
      setVehicleAnalytics(cleanData);
    }
    if (!quotaAnalytics || isAuthenticated) {
      const [quotas] = await Promise.all([getQuotaAnalytics()]);
      const cleanData = removeLeadingZeroMonthsFromQuotas({ ...quotas.res });
      // const cleanData = removeLeadingZeroMonthsFromQuotas({...staticQuotas});
      setQuotaAnalytics(cleanData);
    }
    if (!userAnalytics || isAuthenticated) {
      const [users] = await Promise.all([getUserAnalytics()]);
      const cleanData = removeLeadingZeroMonthsFromUsers({ ...users.res });
      // const cleanData = removeLeadingZeroMonthsFromUsers({...staticUsers});
      setUserAnalytics(cleanData);
    }
    if (!usersList || isAuthenticated) {
      const summary = await Promise.all([getSummaryAnalytics()]);
      // setSummaryAnalytics(summary[0]);
      setUsersList(summary[0]);
    }

    setIsLoading(false);
  }, [isAuthenticated]);

  useEffect(() => {
    setIsLoading(true);

    getAnalytics().catch((err) => {
      setError(err);
      setIsLoading(false);
    });
  }, [isAuthenticated]);

  return (
    <AnalyticsContext.Provider
      value={{
        vehicleAnalytics: vehicleAnalytics!,
        quotaAnalytics: quotaAnalytics!,
        userAnalytics: userAnalytics!,
        usersList: usersList!,
        isLoading,
        error,
        refreshList,
        setRefreshList,
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
};
