import { IUserListStatistics } from 'src/context/useUserAnalytics/userAnalyticsHelper';
import { fetcher } from 'src/utils/legacy/api/fetcher';

import { API_URL } from '../../../../constants/api';

type SummaryAnalyticsType = {} & IUserListStatistics;

export const getSummaryAnalytics = async (): Promise<SummaryAnalyticsType> => {
  // const url = `${API_URL}/vehicle-registrations/analytics`
  const url = `${API_URL}/analytics/summary`;
  return await fetcher(url);
};
