const TOKEN_KEY = 'carbonify-auth-token';
const REFRESH_TOKEN_KEY = 'carbonify-refresh-token';

/**
 * Saves the cookie to browser cookie storage
 * @deprecated Use cognito library instead like Amplify
 */
export const saveTokens = (token: string, refreshToken: string) => {
  // setCookies(TOKEN_KEY, token);
  setTimeout(() => {
    document.cookie = `${TOKEN_KEY}=${token}; path=/`;
    document.cookie = `${REFRESH_TOKEN_KEY}=${refreshToken}; path=/`;
  }, 0);
};

/**
 * Gets the token from the cookie storage
 * Please note that the token is the one coming from the auth endpoint of backend which is an id token.
 */
export const readTokenOldWay = () => {
  // return getCookie(TOKEN_KEY);
  const name = `${TOKEN_KEY}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
/**
 * Gets the token from the cookie storage
 */
export const readRefreshToken = () => {
  // return getCookie(TOKEN_KEY);
  const name = `${REFRESH_TOKEN_KEY}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

/**
 * Removes the cookie from browser cookie storage
 */
export const removeTokens = () => {
  // removeCookies(TOKEN_KEY);
  document.cookie = `${TOKEN_KEY}=${''}; path=/`;
  document.cookie = `${REFRESH_TOKEN_KEY}=${''}; path=/`;
};
