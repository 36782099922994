import { Navigate, useRoutes } from 'react-router-dom';
import { AnalyticsProvider } from 'src/context/useAnalytics/useAnalyticsContext';
import Ping from 'src/pages/Ping';

// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';

//
import {
  // Dashboard: General
  GeneralAppPage,
  // Auth
  LoginPage,
  Page403,
  Page404,
  Page500,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard
    {
      path: '/',
      element: (
        <AuthGuard>
          <AnalyticsProvider>
            <DashboardLayout />
          </AnalyticsProvider>
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate
              to={PATH_AFTER_LOGIN}
              replace
            />
          ),
          index: true,
        },
        { path: 'statistics', element: <GeneralAppPage /> },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '/ping', element: <Ping /> },
    { path: '*', element: <Navigate to="/404" /> },
  ]);
}
