import { PATH_AUTH } from 'src/routes/paths';

import { getAccessToken, isNewAuthEnabled } from '../../../auth';
import { jsonifyAndInjectTotalCount } from '../jsonifyAndInjectTotalCount';

import { readRefreshToken, removeTokens, saveTokens } from './auth';
import { refreshToken } from './refreshToken';

type ICustomHeaders = { [key: string]: string };

/**
 * Generate the request response.
 * It will also set the totalCount property on the
 * response object when it's the case.
 *
 * @param input RequestInfo
 * @param options RequestInit
 * @param customHeaders ICustomHeaders
 * @returns
 */
const performRequest = async (
  input: RequestInfo,
  options?: RequestInit,
  customHeaders?: ICustomHeaders,
  config?: { skipReadOfBody: boolean },
): Promise<any> => {
  const accessToken = await getAccessToken();

  // Replace the common headers with the custom headers if any.
  const headersInput = customHeaders ?? {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };

  const headers = new Headers(headersInput);
  const requestConfig = options ? { ...options, headers } : { headers };

  const res = await fetch(input, requestConfig);

  if (!res.ok) throw new StatusError(res.status, res.statusText);

  if (config?.skipReadOfBody) return res;

  /**
   * Add the x-total-count from the response header.
   * If it's not present then it will have value 0.
   * Inject the totalCount object into the response
   * data and re-create the promise so the rest of
   * the application is not affected.
   */
  return jsonifyAndInjectTotalCount(res);
};

/**
 * Renew the existing token if the provided response has the
 * status 401.
 *
 * @param response any
 * @param input RequestInfo
 * @param options RequestInit
 * @returns
 */
const renewToken = async (input: RequestInfo, options?: RequestInit): Promise<any> => {
  // use refresh token to re-authenticate user
  const refrToken = readRefreshToken();
  const refreshResp = await refreshToken(refrToken);
  // if refresh fails
  if (!refreshResp?.token) {
    // logout
    removeTokens();
    // navigate
    window.location.replace(PATH_AUTH.login);
    return;
  }
  // update tokens
  saveTokens(refreshResp?.token, refreshResp?.refreshToken);
  // re-call the api, create new request to apply new tokens
  return await performRequest(input, options);
};

/**
 * Prepare and get the API request response
 * or throw the necessary errors if it's the case.
 *
 * @param input RequestInfo
 * @returns Promise<any>
 */
export const fetcher = async (
  input: RequestInfo,
  options?: RequestInit,
  customHeaders?: ICustomHeaders,
  config?: { skipReadOfBody: boolean },
): Promise<any> => {
  // make request
  const response = await performRequest(input, options, customHeaders, config);

  // Check if the response status is 401 then refresh the used token but not for new auth
  if (response?.message === 'Unauthorized' && !isNewAuthEnabled()) {
    return renewToken(input, options);
  }

  return response;
};

export class StatusError extends Error {
  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }

  readonly status: number;
}
