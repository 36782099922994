import { fetcher } from 'src/utils/legacy/api/fetcher';

import { API_URL } from '../../../../constants/api';

interface IUserStatsType {
  B2B: number;
  B2C: number;
  total: number;
}

type UserAnalyticsType = {
  total: IUserStatsType;
  monthly: {
    month: string;
    data: IUserStatsType;
  }[];
};

/**
 * It makes a GET request to the `/quota-sales/analytics` endpoint and returns the data
 * @returns A promise that resolves to an object with the following properties:
 */
export const getUserAnalytics = async (): Promise<{ res: UserAnalyticsType; timestamp: string }> => {
  // const url = `${API_URL}/users/analytics`;
  const url = `${API_URL}/analytics/users`;
  return { res: await fetcher(url), timestamp: new Date().toLocaleTimeString() };
};
