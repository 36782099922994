import { API_URL } from 'src/constants/api';

import { fetcher } from './fetcher';

export type LoginUserBody = {
  username: string;
  password: string;
};

export const loginUser = (body: LoginUserBody) => {
  const url = `${API_URL}/auth/login`;
  return fetcher(url, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};
