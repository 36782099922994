import { API_URL } from 'src/constants/api';

import { isNewAuthEnabled } from '../../../auth';

import { fetcher } from './fetcher';

export const refreshToken = (refreshToken: string) => {
  if (isNewAuthEnabled()) throw new Error('Token should be refreshed with Amplify');
  const url = `${API_URL}/auth/refresh-token`;
  return fetcher(url, {
    method: 'POST',
    body: JSON.stringify({ refreshToken }),
  });
};
