import { Deployment } from '../models/deployment';

// Sync with main.tf
const expectedPrefix = 'analytics.';

export const API_URL =
  window.location.host.substring(0, expectedPrefix.length) === expectedPrefix
    ? `https://api.${window.location.host.substring(expectedPrefix.length)}`
    : undefined;

export const WHITELABEL_TENANT: Deployment = 'carbonify';
