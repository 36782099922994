import { VehicleAnalyticsUpdatedType } from 'src/context/useVehicleAnalytics/vehicleAnalyticsHelpers';
import { fetcher } from 'src/utils/legacy/api/fetcher';

import { API_URL } from '../../../../constants/api';

/**
 * It makes a GET request to the `/vehicle-registrations/analytics` endpoint and returns the data
 * @returns A promise that resolves to an object with the following properties:
 */
export const getVehicleAnalytics = async (): Promise<VehicleAnalyticsUpdatedType> => {
  // const url = `${API_URL}/vehicle-registrations/analytics`
  const url = `${API_URL}/analytics/vehicle-registrations`;
  return await fetcher(url);
};
