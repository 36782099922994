import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { translations, withAuthenticator } from '@aws-amplify/ui-react';
import { WithAuthenticatorProps } from '@aws-amplify/ui-react/dist/types/components/Authenticator/withAuthenticator';
import { Stack, Typography } from '@mui/material';
import { I18n } from 'aws-amplify/utils';
import { jwtDecode } from 'jwt-decode';
import { loginUser, LoginUserBody } from 'src/utils/legacy/api/loginUser';

import { getIdToken, isNewAuthEnabled } from '../auth';
import { isValidToken } from '../auth/utils';
import Image from '../components/image';
import { removeTokens } from '../utils/legacy/api/auth';
import { StatusError } from '../utils/legacy/api/fetcher';

import '@aws-amplify/ui-react/styles.css';

// For Amplify UI
I18n.putVocabularies(translations);
I18n.setLanguage('de');

interface IAuthContext {
  isAuthenticated: boolean;
  isInitialized: boolean;
  role: string | null;
  method: string;
  login: (email: string, password: string) => Promise<any>;
  logout: () => void;
  user: any;
  isLoading: boolean;
  update: (token: string) => void;
}

const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  isInitialized: false,
  role: null,
  method: '',
  login: () => Promise.resolve(null),
  logout: () => {},
  user: null,
  isLoading: false,
  update: () => {},
});
export const useAuthContext = (): IAuthContext => useContext(AuthContext);

const OldAuthProvider = (props: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [role, setRole] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();

  const initialize = useCallback(async () => {
    setIsInitialized(true);
    const token = await getIdToken();
    token && isValidToken(token) && update(token);
  }, []);

  const update = (token: string): void => {
    setIsAuthenticated(!!token);
    getRole(token);
    readUserData(token);
  };

  const getRole = (token: string): void => {
    if (token) {
      const properties: any = jwtDecode(token.toString());
      const hasCustomRole = 'custom:role' in properties;
      if (hasCustomRole) {
        setRole(properties['custom:role']);
      }
    }
  };

  const readUserData = (token: string): any => {
    const properties: any = jwtDecode(token.toString());
    const resultingData = {
      cognitoUsername: 'cognito:username' in properties,
      email: 'email' in properties && properties.email,
      lastname: 'family_name' in properties && properties.family_name,
      firstname: 'given_name' in properties && properties.given_name,
      role: 'custom:role' in properties && properties['custom:role'],
      displayName: 'given_name' in properties && properties.given_name,
    };
    setUser(resultingData);
    return resultingData;
  };

  const login = async (email: string, password: string) => {
    setIsLoading(true);

    const body: LoginUserBody = {
      username: email,
      password: password,
    };

    try {
      const token = await loginUser(body);

      if (token) {
        /**
         * Check if the returned token contains
         * the required user role to have access
         * to this application and return the message
         * if not.
         */
        const userDataInToken = readUserData(token.token);

        if (['admin', 'analytics'].includes(userDataInToken.role)) {
          setIsAuthenticated(true);
          setIsInitialized(true);
        } else {
          setIsLoading(false);
          return 'Sie verfügen nicht über die erforderlichen Berechtigungen';
        }
      }

      setIsLoading(false);

      return token;
    } catch (error) {
      setIsAuthenticated(false);
      setIsInitialized(true);
      setIsLoading(false);

      return error instanceof StatusError && error.status === 401
        ? 'Die E-Mail oder das Passwort ist nicht korrekt.'
        : 'Unknown error';
    }
  };

  const logout = useCallback(() => {
    removeTokens();
    setIsAuthenticated(false);
    navigate('/auth/login', { replace: true });
  }, [navigate]);

  useEffect(() => {
    initialize();
  }, []);

  const memoizedValue: IAuthContext = useMemo(
    () => ({
      isInitialized,
      isAuthenticated,
      user,
      method: 'jwt',
      login,
      logout,
      role,
      isLoading,
      update,
    }),
    [isAuthenticated, isInitialized, user, login, logout, isLoading],
  );

  return <AuthContext.Provider value={memoizedValue}>{props.children}</AuthContext.Provider>;
};

const NewAuthProvider = ({ signOut, children }: { children: ReactNode } & WithAuthenticatorProps) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>(undefined);

  useEffect(() => {
    getIdToken().then((token) => {
      const claims = jwtDecode(token);

      const email =
        typeof claims === 'object' && claims !== null && 'email' in claims && typeof claims.email === 'string'
          ? claims.email
          : undefined;

      setEmail(email);
      setIsInitialized(true);
    });
  }, [setEmail]);

  const memoizedValue: IAuthContext = useMemo(
    () => ({
      isInitialized,
      isAuthenticated: isInitialized,
      user: { email },
      method: 'jwt',
      login: () => Promise.resolve(null), // not used
      logout: () => {
        signOut?.();
      },
      role: null, // should not be used anymore
      isLoading: false,
      update: () => {}, // not used
    }),
    [isInitialized, email, signOut],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
};

export const AuthProvider = isNewAuthEnabled()
  ? withAuthenticator(NewAuthProvider, {
      variation: 'default',
      hideSignUp: true,
      components: {
        Header() {
          return (
            <Stack
              spacing={2}
              sx={{ my: 5, position: 'relative' }}
              alignItems="center"
            >
              <Image
                src="/assets/images/carbonify.png"
                sx={{ width: 50 }}
              />
              <Typography variant="h4">Willkommen zurück</Typography>
            </Stack>
          );
        },
      },
    })
  : OldAuthProvider;
