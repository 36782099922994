import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps, Link, Typography } from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';
import { useAuthContext } from 'src/context/useAuthContext';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx }, _ref) => {
  const theme = useTheme();
  const { isAuthenticated } = useAuthContext();

  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Box
          component="img"
          src="/assets/images/carbonify.png"
          sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
        />
        {isAuthenticated && <Typography sx={{ color: theme.palette.secondary.main }}>arbonify</Typography>}
      </Box>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link
      component={RouterLink}
      to="/"
      sx={{ display: 'contents' }}
    >
      {logo}
    </Link>
  );
});

export default Logo;
