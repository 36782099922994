interface EnhancedResponse {
  data: any;
  totalCount?: number;
}

enum TypesEnum {
  X_TOTAL_COUNT,
  HAS_COUNT,
  HAS_CHECKSUM,
  DEFAULT,
}

interface ICountResolverCase {
  condition: (data: any) => boolean;
  type: TypesEnum;
  callback: (data: any, response: any) => Promise<any>;
}

// Always true.
const alwaysTrue = (_data: any) => true;
// Check if object is an array.
const isArray = (data: any) => Array.isArray(data);
// Check if object has a count property.
const hasCount = (data: any) => !Array.isArray(data) && 'count' in data;
// Check if object has a checksum property with a count property inside.
const hasChecksum = (data: any) => !Array.isArray(data) && 'checksum' in data && 'count' in data.checksum;

/**
 * List with all the cases that will help build the final object.
 */
const rules: ICountResolverCase[] = [
  {
    condition: isArray,
    type: TypesEnum.X_TOTAL_COUNT,
    callback: (data: any, response: any) => {
      // Check if the x-total-count header property is present.
      const headerTotalCount = response?.headers?.get('x-total-count');
      /**
       * Re-envelop the response into a promise so the
       * rest of the app is not affected.
       */
      return new Promise((resolve, _) =>
        resolve({
          data: data,
          totalCount: headerTotalCount || 0,
        }),
      );
    },
  },
  {
    condition: hasCount,
    type: TypesEnum.HAS_COUNT,
    callback: (data: any, _response: any) => {
      /**
       * Check if the returned object contains already
       * the count property. In this case we rename it
       * to totalCount for consistency.
       */
      const { count, ...restData } = data;
      const keys = Object.keys(restData).map((key: string) => key);
      return new Promise((resolve, _) => {
        return resolve({
          data: restData[keys[0]],
          totalCount: count || 0,
        });
      });
    },
  },
  // The case in which we have a checksum object
  {
    condition: hasChecksum,
    type: TypesEnum.HAS_CHECKSUM,
    callback: (data: any, _response: any) => {
      /**
       * Check if the returned object contains the
       * checksum property. In this case we rename it
       * to totalCount for consistency.
       */
      const { checksum, ...restData } = data;
      return new Promise((resolve, _) =>
        resolve({
          data: restData,
          totalCount: checksum.count || 0,
        }),
      );
    },
  },
  // All the other cases where we don't need a count object.
  {
    condition: alwaysTrue,
    type: TypesEnum.DEFAULT,
    callback: (data: any, _response: any) => {
      return new Promise((resolve, _) => resolve({ ...data }));
    },
  },
];

/**
 * Get the total object count from the request
 * header property called x-total-count.
 */
export const jsonifyAndInjectTotalCount = async (response: any): Promise<EnhancedResponse> => {
  /**
   * Try and see if the response can be converted
   * to json data or not.
   */
  try {
    const body = await response?.json();
    /**
     * Parse through the list of rules and
     * transform the data accordingly.
     */
    const matchingRule = rules.find((rule: ICountResolverCase) => !!rule.condition(body));
    return matchingRule?.callback(body, response);
  } catch (error: any) {
    return response;
  }
};
